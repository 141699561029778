export function replacer(key: string, value: any) {

    let notWanted = [
        'file',
        'thumbnailFile',
    ]
    if(notWanted.includes(key)){
        return undefined
    }
    return value
}
