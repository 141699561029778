import './Category.css';
import CategoryItem from './CategoryItem';
import React from 'react';
import ContentItem from '../../models/ContentItem';


export const Category: React.FC<{
    contentId:string,
    name: string,
    items: Array<ContentItem>
}> = props => {

    return <div>
        <h2 className="CategroyEdit-header">{props.name}</h2>
        <div className="CategoryEdit-items">
            {props.items.map((item, index) =>
                <CategoryItem
                    key={`${item.name}: ${index}`}
                    item={item}
                    contentId={props.contentId}
                />
            )}
        </div>
    </div>
}

export default Category;