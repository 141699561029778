import React from 'react';
import { useHistory } from "react-router-dom";
import './Header.css'

export const Header: React.FC<{
    title: string | undefined
}> = props => {

    const history = useHistory()

    var backClicks = 0

    function headerBack(){
        backClicks++
        if (backClicks >= 5){
            history.push("/")
        }
    }

    return <div className="Header">
        <img src={'/assets/images/usu_logo.png'} alt="logo" className="Header-icon" />
        <h1 className="Header-title">{props.title}</h1>
        <div className="Header-back" onClick={headerBack}/>
    </div>
}

export default Header