import React from 'react';
import { Link } from 'react-router-dom'
import Header from './ChooseHeader';
import './Choose.css';
import Content from '../../models/Content';



export const Choose: React.FC<{
  data: Content[],
  addContent: (newContent: Content) => void,
  deleteContent: (contetn:Content) => void,
}> = props => {

  return (
    <div className="Choose" >
      <Header title="Choose Your Content" addContent={props.addContent} />
      <div className="ChooseEdit-body">
        {props.data.map((content, index) =>
          <div className="ChooseEdit-item" key={content.title + String(index)} >
            <Link to={`/edit/${content.id}`}>
              <h1 className='ChooseEdit-text' > {content.title} </h1>
            </Link>
            <button className="ChooseEdit-delete" onClick={() => props.deleteContent(content)}>
              <div className="ChooseEdit-delete-text">
                Delete
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Choose;