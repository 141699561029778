
import ContentItem from './ContentItem'

export class Category{
  name:string
  items:ContentItem[]
  constructor(name:string, items:ContentItem[]){
    this.name = name
    this.items = items
  }
}

export default Category