import './CategoryItem.css';
import { Link } from 'react-router-dom'
import React, { useEffect } from 'react';
import ContentItem from '../../models/ContentItem';

export const CategoryItem: React.FC<{
    contentId: string,
    item: ContentItem,
}> = props => {

    useEffect(() => {
        if (props.item.thumbnailFile?.length) {
            let file = props.item.thumbnailFile?.item(0)
            var imgtag = document.getElementById(`image${props.item.id}`) as HTMLImageElement | undefined;
            if (file && imgtag) {
                var reader: FileReader | undefined
                reader = new FileReader()
                imgtag.title = file.name;
                reader.onload = function(event){
                    if(imgtag && event){
                        let result = event?.target?.result
                        if (typeof result === 'string') imgtag.src = result
                    }
                }
                reader.readAsDataURL(file);
            }
        }
    }, [])

    return <Link to={`/edit/${props.contentId}/article/${props.item.id}`}>
        <div className="ItemEdit">
            {props.item.thumbnailName ?
                <img
                    id={`image${props.item.id}`}
                    src={props.item.thumbnailFile ? props.item.thumbnailFile[0].name : props.item.thumbnailName ? `/content/images/${props.item.thumbnailName}` : ""}
                    className="ItemEdit-image"
                    alt={props.item.name}
                />
                :
                <div className='ItemEdit-default'>
                    <img
                        src="/assets/images/thumbnail_default.png"
                        className='Item-default-image'
                        alt="Default Thumbnail"
                    />
                    <p className='ItemEdit-default-text'>
                        {props.item.name.substring(0, 15)}
                    </p>
                </div>
            }
            <p className="ItemEdit-text">{props.item.name.substring(0,30)}</p>
        </div>
    </Link>

}


export default CategoryItem;