import React from 'react';
import { Link } from 'react-router-dom';
import Content, { defaultContent } from '../../models/Content';
import './Header.css'

export const Header: React.FC<{
    title: string,
    addContent: (newContent: Content) => void,
}> = props => {
    return <div className="HeaderEdit">
        <img src={'/assets/images/usu_logo.png'} alt="logo" className="HeaderEdit-icon" />
        <h1 className="HeaderEdit-title">Editing {props.title}</h1>
        <div className="HeaderEdit-other">
            <button className='HeaderEdit-add'>
                <div className="HeaderEdit-button-text" onClick={(e) => {
                    let newContent = defaultContent()
                    newContent.title = "New Content"
                    props.addContent(newContent)
                }}>New Item</div>
            </button>
            <Link to={`/edit/auth`} className='HeaderEdit-add'>
              <div className='HeaderEdit-button-text' > Authentication </div>
            </Link>
        </div>
    </div>
}

export default Header