import ContentItem from "./ContentItem"
import Sorts from "./Sorts"
import {v4 as uuidv4} from 'uuid';


export interface Content {
    id:string
    title: string
    groupOptions: (keyof typeof Sorts)[]
    defaultGroup: keyof typeof Sorts
    items: ContentItem[]
}

export function defaultContent(): Content {
    return {
        id:uuidv4(),
        title: "",
        groupOptions: [
            "Year",
            "Sports",
            "Name"
        ],
        defaultGroup: "Name",
        items: []
    } as Content
}
export default Content