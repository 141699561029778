import React from 'react';
import { useHistory } from "react-router-dom";
import './Video.css';
import ContentItem from '../../models/ContentItem';

export const Video: React.FC<{ item: ContentItem }> = props => {
    let history = useHistory()

    function playClicked() {
        let elem = document.getElementById('video') as HTMLVideoElement | null
        if (elem) {
            elem.addEventListener('ended', function () {
                if (elem) {
                    elem.autoplay = false
                    elem.load()
                }
            })
            elem.requestFullscreen()
            elem.play()
        }
    }

    return <div className="Video">
        <div className='Video-left'>
            <video
                id="video"
                className='Video-video'
                preload="auto"
                poster={props.item.thumbnailName ? `/content/images/${props.item.thumbnailName}` : '/assets/images/thumbnail_default_plain.png'}
                onEnded={ev => {
                    document.exitFullscreen()
                }}
            >
                <source className='Video-video' src={`/content/videos/${props.item.fileName}`} type={`video/${props.item.fileName.split('.').pop()}`} />
            </video>
            <div className='Video-lower'>
                <div
                    className='Video-button-back'
                    onClick={e => { history.goBack() }}
                >
                    <img
                        src="/assets/images/left_arrow.png"
                        className="Video-back-arrow"
                        alt="arrow"
                    />
                    <div className="Video-button-text">Back</div>
                </div>
                <div
                    className='Video-button-play'
                    onClick={e => { playClicked() }}
                >
                    <div className="Video-button-text">Play</div>
                </div>
            </div>
        </div>
        <div className="Video-info">
            <h3 className="Video-text">{props.item.name}</h3>
            <h4 className="Video-text">{props.item.sports.join(',')}</h4>
            <p className="Video-text">{props.item.active}</p>
            <pre className="Video-description">{props.item.description}</pre>
        </div>

    </div>
}

export default Video;