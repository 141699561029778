import React, { useState, } from 'react';
import Content from '../../models/Content';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom'
import './Header.css'

export const Header: React.FC<{
    data: Content,
    contentId: string
    onSave:(content:Content) => void
}> = props => {

    const [title, setTitle] = useState(props.data.title)
    const history = useHistory()

    function back() {
        history.push("/edit")
    }

    return <div className="HeaderEdit">
        <img src={'/assets/images/usu_logo.png'} alt="logo" className="HeaderEdit-icon" />
        <input className="HeaderEdit-title-edit" type="text" value={title} onChange={ev => { setTitle(ev.target.value); props.data.title = ev.target.value }} />
        <div className="HeaderEdit-other">
            <Link className='HeaderEdit-add' to={`/edit/${props.contentId}/new`} >
                <h4 className="HeaderEdit-button-text">New Item</h4>
            </Link>

            <button className='HeaderEdit-add' onClick={e => props.onSave(props.data)} >
                <h4 className="HeaderEdit-button-text">Save</h4>
            </button>

            <button className='HeaderEdit-save' onClick={back} >
                <h4 className="HeaderEdit-button-text">Back</h4>
            </button>
        </div>
    </div>
}

export default Header