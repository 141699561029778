import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import Content from '../../models/Content';
import Sorts from '../../models/Sorts';
import ContentItem from '../../models/ContentItem';
import Category from '../../models/Category';
import CategorySection from './Category'
import CategoryItem from './CategoryItem';
import Header from './MainHeader';
import './Main.css';



export const Main: React.FC<{ 
  data: Content,
  contentId:string,
  onSave:(content:Content) => void,
}> = props => {
  const [categories, setCategories] = useState<Category[] | undefined>(undefined)
  let location = useLocation()

  useEffect(() => {
    let hash = location.hash.slice(1) ? location.hash.slice(1) : props.data.defaultGroup
    const map = new Map<string, ContentItem[]>()
    
    function setItemInMap(item: ContentItem, key: string) {
      const group = map.get(key)
      if (!group) {
        map.set(key, [item])
      } else {
        group.push(item)
      }
    }

    props.data.items.forEach((item: ContentItem) => {
      let field = Sorts[hash as keyof typeof Sorts](item)
      if (field !== undefined) {
        if (Array.isArray(field)) {
          if (field.length == 0) {
            setItemInMap(item, "Other")
          } else {
            field.forEach(value => {
              setItemInMap(item, value)
            })
          }
        } else {
          var key = String(field)
          setItemInMap(item, key ? key : "Other")
        }
      }
    })
    if (map.size > 0) {
      var cats: Category[] = []
      map.forEach((items, key) => {
        cats.push(
          new Category(
            key,
            items.sort((item1, item2) => item1.name < item2.name ? -1 : 1)
          )
        )
      })
      cats.sort((cat1, cat2) => cat1.name < cat2.name ? -1 : 1)
      setCategories(cats)
    } else {
      setCategories(undefined)
    }
  }, [location])

  return (
    <div className="MainEdit" >
      <Header data={props.data} contentId={props.contentId} onSave={props.onSave} />

      <div className="MainEdit-body">
        {props.data.groupOptions.length > 0 ?
          <div className="MainEdit-groups">
            <p className="MainEdit-group-text">Sort By: </p>
            {props.data.groupOptions.map((option, index) => {
              return <Link key={option + String(index)} to={{ hash: `${option}` }}><div key={option} className='MainEdit-group-item'>
                <img
                  className='MainEdit-group-item-image'
                  alt={`Sort: ${option}`}
                  src={option === (location.hash.slice(1) ? location.hash.slice(1) : props.data.defaultGroup) ? "/assets/images/sort_checked.png" : "/assets/images/sort_unchecked.png"}
                />
                <p className='MainEdit-group-item-text'>
                  {option}
                </p>
              </div>
              </Link>
            })}
          </div>
          : <div></div>
        }
        {categories ? (
          categories.map((cat, index) =>
            <CategorySection
              key={`${cat.name}: ${index}`}
              name={cat.name}
              items={cat.items}
              contentId={props.contentId}
            />
          )
        ) :
          <div className="MainEdit-grid">
            {props.data.items.sort((item1, item2) => item1.name < item2.name ? -1 : 1).map((item, index) =>
              <CategoryItem
                key={`${item.name}: ${index}`}
                item={item}
                contentId={props.contentId}
              />
            )}
          </div>
        }
      </div>
    </div>
  );
}

export default Main;
