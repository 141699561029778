import { useState, useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import './App.css'
import ViewingChoose from './Viewing/Choose'
import ViewingMain from './Viewing/Main'
import ViewingArticle from './Viewing/Article'
import EditingMain from './Editing/Main'
import EditingArticle from './Editing/Article'
import EditingChoose from './Editing/Choose'
import Content, { defaultContent } from '../models/Content'
import ContentItem, { defaultContentItem } from '../models/ContentItem'
import ContentStructure from '../models/ContentStructure'
import { replacer } from '../models/JsonReplacer'
import EditAuth from './Editing/EditAuth'

function App() {

  const [contentOptions, setContentOptions] = useState<Content[]>([])
  const [error, setError] = useState<any | undefined>(undefined)
  const [saving, setSaving] = useState<boolean>(false)
  const history = useHistory();

  window.onbeforeunload = function () {
    if (window.location.pathname.startsWith("/edit")) {
      return "Leaving or refreshing while editing will cause all data to be lost.";
    }
    //if we return nothing here (just calling return;) then there will be no pop-up question at all
    return
  };

  useEffect(() => {
    fetch('/content/files.json')
        .then(repsonse => repsonse.json())
        .then(response => Promise.all((response as ContentStructure).jsonFiles.map((file) => fetch(`/${file.name}`))))
      .then(response => Promise.all(response.map(item => item.json())))
      .then(x => x as Content[])
      .then(content => {
        setContentOptions(content)
        if (!window.location.pathname.startsWith("/edit")) {
          if (content.length === 1) {
            history.replace(`/${content[0].id}#${content[0].defaultGroup ?? ""}`)
          } else if (content.length > 1) {
            let defaultContent = localStorage.getItem('defaultContent')
            if (defaultContent) {
              let defaultdata = content.find(data => data.id === defaultContent)
              if (defaultdata) history.push(`/${defaultContent}#${defaultdata?.defaultGroup ?? ""}`)
            }
          }
        }
      })
      .catch(error => setError(error))
      .finally(() => { })
  }, [])

  function addNewContent(newContent: Content) {
    contentOptions.push(newContent)
    setContentOptions(Array<Content>().concat(contentOptions))
  }

  function deleteContent(content: Content) {
    let result = window.confirm(`Are you sure you want to delete ${content.title}? This action will be irreversible, and the media it relied on will be slowly cleaned up if not used elsewhere.`)
    if (result) {
      fetch(
        `/delete/${content.id}`,
        {
          method: "DELETE"
        } as RequestInit
      )
        .then(response => {
          if (Math.floor(response.status / 200) > 1) {
            return response.text()
          } else {
            setContentOptions(Array<Content>().concat(contentOptions.filter(item => item.id !== content.id)))
            return undefined
          }
        })
        .then(text => { if (text) { window.alert(text) } })
        .catch(error => window.alert(error))
    }
  }

  function saveContent(content: Content) {
    let result = window.confirm(`Are you sure you want to save ${content.title}. This will only save this option and will replace the content for this option on the server.`)
    if (result) {
      var needsUpload = Array<FileList>()
      content.items.forEach((item, index) => {
        item.id = index
        if (item.thumbnailFile) {
          needsUpload.push(item.thumbnailFile)
        }
        if (item.file) {
          needsUpload.push(item.file)
        }
      })
      const form = new FormData()
      const contentJson = JSON.stringify(content, replacer)
      const blob = new Blob([contentJson], { type: 'application/json' });
      const fileName = content.id + ".json"
      const file = new File([blob], fileName, {type: 'application/json'});
      form.append(fileName, file)
      needsUpload.forEach((item, index) => {
        let fileItem = item[0]
        form.append(fileItem.name, fileItem)
      })
      setSaving(true)
      fetch(
        "/upload",
        {
          method: "POST",
          body: form,
        } as RequestInit
      )
        .then(response => {
          if (Math.floor(response.status / 200) === 2) {
            return response.text()
          }
          return response.json()
        })
        .then(response => {
          if (typeof response === 'string') {
            window.alert(response)
          } else {
            (response as Array<Content>).forEach(data => {
              let currentIndex = contentOptions.findIndex(item => {
                return item.id === data.id
              })
              if (currentIndex !== null || currentIndex !== undefined) {
                contentOptions[currentIndex] = data
              }
            })
          }
        })
        .catch(error => window.alert(error))
        .finally(() => { setSaving(false) })
    }
  }


  return (
    <div className='App' id='app'>
      <div id='content' className="App-content">
        {
          error ? (
            <h1 className='App-error'>An error occured on fetching data {String(error)}</h1>
          ) : contentOptions ? (
            <Switch>
              <Route path={"/edit/auth"}> <EditAuth />
              </Route>
              <Route exact path={'/edit/:content/article/:id'} children={({ match }) => {
                let data: Content = contentOptions.find(item => item.id === match?.params?.content ?? "-1") ?? defaultContent()
                return <EditingArticle data={data} item={(data.items as ContentItem[]).find(item => String(item.id) === match?.params?.id) ?? defaultContentItem()} />
              }} />
              <Route exact path={'/edit/:content/new'} children={({ match }) => <EditingArticle data={contentOptions.find(item => item.id === match?.params?.content ?? "-1") ?? defaultContent()} item={undefined} />} />
              <Route path={'/edit/:content'} children={({ match }) => {
                let data = contentOptions.find(item => item.id === match?.params?.content ?? "-1") ?? defaultContent()
                return <EditingMain contentId={data.id} data={data} onSave={saveContent} />
              }
              } />
              <Route path={'/edit'}>
                <EditingChoose data={contentOptions} addContent={addNewContent} deleteContent={deleteContent} />
              </Route>
              <Route exact path={'/:content/article/:id'} children={({ match }) => {
                let data = contentOptions.find(item => item.id === match?.params?.content ?? "-1") ?? defaultContent()
                return <ViewingArticle item={(data.items as ContentItem[])?.find(item => String(item.id) === match?.params?.id) ?? defaultContentItem()} />
              }} />
              <Route path={'/:content'} children={({ match }) => {
                let data = contentOptions.find(item => item.id === match?.params?.content ?? "-1") ?? defaultContent()
                return <ViewingMain data={data} contentId={data.id} />
              }} />
              <Route path={'/'}> <ViewingChoose data={contentOptions} /></Route>
            </Switch>
          ) : <h1 className='App-error'>Fetching content</h1>
        }
      </div>
      {saving ? <div className="App-loader">
        <h1>Uploading</h1>
        <div className="Loader" />
        <div>This can take a long time depending on how much you are uploading.</div>
      </div> : <div />}
    </div>
  );
}

export default App;