import { DiffieHellman } from 'crypto';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './EditAuth.css';

// let base64 = require('base-64');

export const EditAuth: React.FC<{
}> = props => {
  const [currentUsername, setCurrentUsername] = useState<string>("")
  const [currentPassword, setCurrentPassword] = useState<string>("")
  const [newUsername, setNewUsername] = useState<string>("")
  const [newPassword, setNewPassword] = useState<string>("")
  const [confirmPassword, setConfirmPassword] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)

  const history = useHistory()

  return (
    <div className="EditAuth" >
      {loading ? <div className="EditAuth-Loader"/> :
        <div className="EditAuth-Form">
          <div className="EditAuth-Header">Enter new authentication information.</div>
          <label className="EditAuth-Label">
            Current Username:
          </label>
          <input className="EditAuth-Input" type="text" value={currentUsername} onChange={ev => setCurrentUsername(ev.target.value)} />
          <label className="EditAuth-Label">
            Current Password:
          </label>
          <input className="EditAuth-Input" type="password" value={currentPassword} onChange={ev => setCurrentPassword(ev.target.value)} />
          <label className="EditAuth-Label">
            New Username (Optional):
          </label>
          <input className="EditAuth-Input" type="text" value={newUsername} onChange={ev => setNewUsername(ev.target.value)} />
          <label className="EditAuth-Label">
            New Password:
          </label>
          <input className="EditAuth-Input" type="password" value={newPassword} onChange={ev => setNewPassword(ev.target.value)} />
          <label className="EditAuth-Label">
            Confirm Password:
          </label>
          <input className="EditAuth-Input" type="password" value={confirmPassword} onChange={ev => setConfirmPassword(ev.target.value)} />
          <button className="EditAuth-Button" onClick={e => {
            if (newPassword !== "" && confirmPassword !== "") {
              setLoading(true)
              let headers = new Headers();
              headers.set('Authorization', 'Basic ' + btoa(currentUsername + ":" + currentPassword));
              fetch(
                "/update_credentials",
                {
                  method: "POST",
                  headers: headers,
                  body: JSON.stringify({username: newUsername !== "" ? newUsername : currentUsername, newPassword: newPassword , confirmPassword: confirmPassword})
                } as RequestInit
              )
                .then(response => {
                  if (response.status === 200) {
                    history.goBack()
                  } else if (response.status === 401){
                    window.alert("Current username or password provided is invalid")
                  }else{
                    window.alert("New username or password provided is invalid")
                  }
                })
                .catch(e => window.alert("New username or password provided is invalid"))
                .finally(() => { setLoading(false) })

            } else {
              window.alert("You must enter a new username and password")
            }
          }}>Submit</button>

          <button onClick={e => history.goBack()}>Cancel</button>
        </div>
      }
    </div>
  );
}

export default EditAuth;
