import React from 'react';
import Header from './Header';
import './Article.css';
import ContentItem from '../../models/ContentItem';
import Video from './Video';
import TextArticle from './TextArticle';

export const Article: React.FC<{ item: ContentItem }> = props => {

    function renderSwitch(contentType: string) {
        switch (contentType) {
            case "video": {
                return <Video item={props.item} />
            }
            case "text": {
                return <TextArticle item={props.item} />
            }
            default: {
                return <Video item={props.item} />
            }
        }
    }

    return <div className="Article">
        <Header title={props.item.name} />
        <div className='Article-body'>
            {renderSwitch(props.item.contentType)}
        </div>
        <div className="Article-footer"></div>
    </div>
}


export default Article;