
export interface ContentItem {
    id: number
    contentType: string
    name: string
    description: string
    year: string
    sports: string[]
    active: string
    fileName: string
    file: FileList | null
    thumbnailName: string
    thumbnailFile: FileList | null
}


export function defaultContentItem(): ContentItem {
    return {
        id: -1,
        contentType: "",
        name: "",
        description: "",
        year: "",
        sports: [],
        active: "",
        fileName: "",
        file:null,
        thumbnailName: "",
        thumbnailFile:null,
    } as ContentItem
}


export default ContentItem