import React from 'react';
import './Article.css';
import ContentItem from '../../models/ContentItem';
import { useHistory } from "react-router-dom";
import Video from './Video';
import TextArticle from './TextArticle';
import Header from './Header';
import Content from '../../models/Content';


let info = `
When selecting a video or tumbnail file, make sure the names are unique, and do not already exist on the server. Currently the server does not support file renaming and will over write what already exists.
`

export const Article: React.FC<{ 
    data: Content, 
    item: ContentItem | undefined 
}> = props => {
    let history = useHistory()

    let item: ContentItem = props.item ?? {
        id: (props.data.items.sort((item1, item2) => item1.id - item2.id )[props.data.items.length - 1]?.id ?? 0) + 1,
        contentType: "video",
        name: "",
        description: "",
        year: "",
        sports: [],
        active: "",
        fileName: "",
        file:null,
        thumbnailName: "",
        thumbnailFile:null,
    }

    function save(){
        if(props.item){
            history.goBack()
        }else{
            props.data.items.push(item)
            history.goBack()
        }
    }

    function deleteItem(){
        let items = props.data.items.filter(it => it.id !== props.item?.id ?? -1)
        props.data.items = items
        history.goBack()
    }

    function renderSwitch(contentType: string) {
        switch (contentType) {
            case "video": {
                return <Video item={item} onSave={save} onDelete={deleteItem} />
            }
            case "text": {
                return <TextArticle item={item} />
            }
            default: {
                return <Video item={item} onSave={save} onDelete={deleteItem} />
            }
        }
    }

    return <div className="ArticleEdit">
        <Header title={props.item?.name ?? "New Item"} />

        <div className='ArticleEdit-body'>
            {renderSwitch("video")}
            <div className="ArticleEdit-info">
                {info}
            </div>
        </div>
        <div className="ArticleEdit-footer"></div>
    </div>
}


export default Article;