import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import './Video.css';
import ContentItem from '../../models/ContentItem';

export const Video: React.FC<{
    item: ContentItem,
    onSave: () => void,
    onDelete: () => void,
}> = props => {
    let history = useHistory()

    const [contentType] = useState(props.item.contentType)
    const [name, setName] = useState(props.item.name)
    const [description, setDescription] = useState(props.item.description)
    const [year, setYear] = useState(props.item.year)
    const [sports, setSports] = useState(props.item.sports)
    const [newSport, setNewSport] = useState("")
    const [active, setActive] = useState(props.item.active)
    const [fileName, setFileName] = useState(props.item.fileName)
    const [file, setFile] = useState<FileList | null>(props.item.file)
    const [thumbnailName, setThumbnailName] = useState(props.item.thumbnailName)
    const [thumbnailFile, setThumbnailFile] = useState<FileList | null>(props.item.thumbnailFile)


    function save() {
        props.item.contentType = contentType
        props.item.name = name
        props.item.description = description
        props.item.year = year
        props.item.sports = sports
        props.item.active = active
        props.item.fileName = fileName
        props.item.file = file ?? props.item.file
        props.item.thumbnailName = thumbnailName
        props.item.thumbnailFile = thumbnailFile ?? props.item.thumbnailFile
        props.onSave()
    }

    function cancel() {
        history.goBack()
    }

    function removeSport(id: string) {
        let index = sports.indexOf(id, 0)
        sports.splice(index, 1)
        setSports(Array<string>().concat(sports))
    }

    function addSportClicked() {
        setSports([newSport].concat(sports))
        setNewSport("")
    }


    useEffect(() => {
        if (thumbnailFile?.length) {
            let file = thumbnailFile?.item(0)
            var imgPreview = document.getElementById("thumbnail-preview") as HTMLImageElement | undefined;
            if (file && imgPreview) {
                var reader: FileReader | undefined
                reader = new FileReader()
                imgPreview.title = file.name;
                reader.onload = function (event) {
                    if (imgPreview && event) {
                        let result = event?.target?.result
                        if (typeof result === 'string') imgPreview.src = result
                    }
                }
                reader.readAsDataURL(file);
            }
        }
    }, [thumbnailFile, thumbnailName])

    useEffect(() => {
        if (file?.length) {
            let videoFile = file?.item(0)
            var videoPreview = document.getElementById("video-preview") as HTMLImageElement | undefined;
            if (videoFile && videoPreview) {
                videoPreview.title = videoFile.name;
                videoPreview.src = URL.createObjectURL(videoFile);
            }
        }
    }, [file, fileName])

    return <div className="VideoEdit">
        <div className="VideoEdit-left">

            <label className="VideoEdit-field-header">
                Video File: {fileName ?? ""}
            </label>
            {file ?
                <video
                    id="video-preview"
                    controls
                    className="VideoEdit-thumbnail-preview"
                    preload="auto"
                    onEnded={ev => {
                        document.exitFullscreen()
                    }}
                >
                </video>
                : props.item.fileName ?
                    <video
                        id="video-preview"
                        controls
                        className="VideoEdit-thumbnail-preview"
                        preload="auto"
                        onEnded={ev => {
                            document.exitFullscreen()
                        }}
                    >
                        <source className='Video-video' src={`/content/videos/${props.item.fileName}`} type={`video/${props.item.fileName.split('.').pop()}`} />
                    </video>
                    : <div />
            }
            <input className="VideoEdit-input" type="file" id="file" onChange={ev => { setFile(ev.target.files); setFileName(ev.target.files?.item(0)?.name ?? "") }} />
            <label className="VideoEdit-field-header">
                Thumbnail: {thumbnailName ?? ""}
            </label>
            {thumbnailFile ?
                <img id="thumbnail-preview" className="VideoEdit-thumbnail-preview" alt="Thumbnail Preview"></img>
                : props.item.thumbnailName ?
                    <img src={`/content/images/${props.item.thumbnailName}`} className="VideoEdit-thumbnail-preview"  alt="Thumbnail Preview"></img>
                    : <div />
            }
            <input className="VideoEdit-input" type="file" id="thumbnail" onChange={ev => { setThumbnailFile(ev.target.files); setThumbnailName(ev.target.files?.item(0)?.name ?? "") }} />
            <label className="VideoEdit-field-header">
                Name:
            </label>
            <input className="VideoEdit-input" type="text" id="name" value={name} onChange={ev => setName(ev.target.value)} />
            <label className="VideoEdit-field-header">
                Sports:
            </label>
            <ul id="sportsList">
                {sports.map((sport, index) => {
                    return <li key={sport + String(index)}>
                        <div className="VideoEdit-sport-item" >
                            <div>{sport}</div>
                            <button className="VideoEdit-sport-remove" onClick={e => removeSport(sport)}>Remove</button>
                        </div>
                    </li>
                })}
            </ul>
            <div className="VideoEdit-input">
                <input type="text" id="sportField" value={newSport} onChange={ev => setNewSport(ev.target.value)} /> <button onClick={e => addSportClicked()}>Add Sport</button>
            </div>
            <label className="VideoEdit-field-header">
                Year:
            </label>
            <input className="VideoEdit-input" type="text" id="year" value={year} onChange={ev => setYear(ev.target.value)} />
            <label className="VideoEdit-field-header">
                Active:
            </label>
            <input className="VideoEdit-input" type="text" id="active" value={active} onChange={ev => setActive(ev.target.value)} />
            <label className="VideoEdit-field-header">
                Description:
            </label>
            <textarea className="VideoEdit-description" id="description" value={description} onChange={ev => setDescription(ev.target.value)} />

        </div>
        <div className="VideoEdit-right">
            <button className="VideoEdit-buttons" onClick={cancel}>Cancel</button>
            <button className="VideoEdit-buttons" onClick={save}>Save</button>
            <button className="VideoEdit-buttons" onClick={props.onDelete}>Delete</button>
        </div>
    </div>
}

export default Video;