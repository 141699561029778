import React from 'react';
import './TextArticle.css';
import ContentItem from '../../models/ContentItem';


export const TextArticle: React.FC<{ item: ContentItem }> = props => {
    return <div className="TextArticleEdit">
        <pre>
            {props.item.name}
        </pre>
    </div>
}


export default TextArticle;