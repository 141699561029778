import React from 'react';
import { Link } from 'react-router-dom'
import Header from './Header';
import './Choose.css';
import Content from '../../models/Content';



export const Choose: React.FC<{ data: Content[] }> = props => {

  return (
    <div className="Choose" >
      <Header title="Choose Your Content" />
      <div className="Choose-body">
        {props.data.map((content, index) =>
          <Link to={`/${content.id}`} >
            <h1 key={content.title} className='Choose-lower' onClick={e => localStorage.setItem("defaultContent", content.id)}> {content.title} </h1>
          </Link>
        )}
      </div>
    </div>
  );
}

export default Choose;