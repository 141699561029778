import './CategoryItem.css';
import { Link } from 'react-router-dom'
import React from 'react';
import ContentItem from '../../models/ContentItem';

export const CategoryItem: React.FC<{
    contentId:string,
    item: ContentItem,
}> = props => {

    return <Link to={`${props.contentId}/article/${props.item.id}`} >
        <div className="Item">
            {props.item.thumbnailName ?
                <img
                    src={`/content/images/${props.item.thumbnailName}`}
                    className="Item-image"
                    alt={props.item.name}
                />
                :
                <div className='Item-default'>
                    <img
                        src="/assets/images/thumbnail_default.png"
                        className='Item-default-image'
                        alt="Default Thumbnail"
                    />
                    <p className='Item-default-text'>
                        {props.item.name}
                    </p>
                </div>
            }
            <p className="Item-text">{props.item.name}</p>
        </div>
    </Link>

}


export default CategoryItem;