import React from 'react';
import './Header.css'

export const Header: React.FC<{
    title: string
}> = props => {
    return <div className="HeaderEdit">
        <img src={'/assets/images/usu_logo.png'} alt="logo" className="HeaderEdit-icon" />
        <h1 className="HeaderEdit-title">Editing {props.title}</h1>
    </div>
}

export default Header